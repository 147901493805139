import React from 'react';
import { Link } from 'react-router-dom';

import ContactIcons from '../Contact/ContactIcons';

const { PUBLIC_URL } = process.env; // set automatically from package.json:homepage

const SideBar = () => (
  <section id="sidebar">
    <section id="intro">
      <Link to="/" className="logo">
        <img src={`${PUBLIC_URL}/images/me.jpg`} alt="" />
      </Link>
      <header>
        <h2>Jay Soni</h2>
        <p>
          <a href="mailto:hi@jaysoni.dev">hi@jaysoni.dev</a>
        </p>
      </header>
    </section>

    <section className="blurb">
      <h2>About Jay Soni</h2>
      <p>As a seasoned Full Stack Software Engineer specializing in Angular2+, Django,
        and Ionic technologies, I take pride in crafting scalable and robust digital solutions.
        Paramount to my development philosophy is adherence to Agile Methodologies,
        ensuring high-productivity and adaptiveness in dynamic project landscapes.
        My unwavering commitment to writing quality code has underpinned my success in
        creating efficient, error-free, and maintainable systems.
        I am <a href="https://www.rgpv.ac.in/" target="_blank" rel="noreferrer">Engineer (Computer Science graduate)</a> and <a href="https://infosci.arizona.edu/ms-data-science">Data Scientist</a>,
        <a href="https://www.tcs.com/" target="_blank" rel="noreferrer">TATA Alumni</a>,
        <a href="https://infosci.arizona.edu/ms-data-science" target="_blank" rel="noreferrer">UOA - USA Alumni </a> and
        the Co-Founder of <a href="https://goclever.in" target="_blank" rel="noreferrer">Go Clever IT Consultancy</a>.
      </p>
      <ul className="actions">
        <li>
          {!window.location.pathname.includes('/resume') ? <Link to="/resume" className="button">Learn More (Resume)</Link> : <Link to="/about" className="button">About Me(About)</Link>}
        </li>
      </ul>
    </section>

    <section id="footer">
      <ContactIcons />
      <p className="copyright blue-color">&copy; Jay Soni <Link to="/">jaysoni.dev</Link>.</p>
      <p className="copyright blue-color">Updates: June 21, 2024</p>
    </section>
  </section>
);

export default SideBar;
